import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Tooltip,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import ButtonComponent from '../../../../common/components/button/Button'
import CancelIcon from '@mui/icons-material/Cancel'
import {
  checkPermission,
  executeWithTryCatch,
  gettingCompanyId,
  isResponseSuccess,
  normalizeApiResponse,
  requiredLabel,
} from '../../../../helpers/HelperFunctions'
import { useFormik } from 'formik'
import { DirectRoutingConfigFormValidation } from '../../../../validations/ServicesValidation'
import { PERMISSIONS, TOAST_MESSAGES } from '../../../../helpers/Constants'
import { useState, useMemo, useEffect } from 'react'
import { postDRConfiguration } from '../../../../api/post/postApi'
import { useToastContext } from '../../../../App'
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import GppGoodIcon from '@mui/icons-material/GppGood'
import ConfirmationModal from '../../../../common/components/custom/modal/modal'
import { setIsOrderActivatedView } from '../../../../store/slice/homeSlice'
import { useHomeRedux } from '../../Home'
import { PRODUCT_NAMES } from '../../../../helpers/Constants'
import { api_generateM365License } from '../../../../api/master/masterAPI'
import { FlexBox } from '../../../../common/styles/styled-components/StyledContainers'
import { api_AssignVoiceRoutePolicy } from '../../../../api/services/TeamsPhoneSolutionsAPI'
import { CaptionText } from '../../../../common/styles/styled-components/StyledTypography'
import {
  serviceSliceState,
  setConfiguration,
} from '../../../../store/slice/serviceSlice'
import SkeletonTableLoader from '../../../../common/components/loaders/skeletonLoader'
import { getCustomerConfiguration } from '../../../../api/get/getApi'
import { useSelector } from 'react-redux'
import EditIcon from '@mui/icons-material/Edit'

const FlexContainer = styled(Grid)({
  display: 'flex',
  justifyContent: 'start',
  margin: '10px 0',
})
const CustomTextField = styled(TextField)({
  '& .MuiFormHelperText-root': {
    fontSize: '12px', // Adjust the font size as needed
  },
})

function DirectRoutingConfigDialog(props) {
  const {
    onClose,
    onChange,
    serviceName,
    goToServiceFunction,
    updateAdministrate,
  } = props
  const [btnLoading, setBtnLoading] = useState({
    saveLoading: false,
    generateLicenseLoading: false,
    submitLoading: false,
  })
  const { toast } = useToastContext()
  const serviceState = useSelector(serviceSliceState)
  const [searchParams] = useSearchParams()
  const { dispatch } = useHomeRedux()
  const OrderActivated = localStorage.getItem('OrderActivated')
  const isAlreadyActivated = localStorage.getItem('isAlreadyActivated')
  const [showPassword, setshowPassword] = useState(false)
  const { state: commonState } = useCommonRedux()
  const [showConsentAlert, setShowConsentAlert] = useState(false)
  const [showPasswordEdit, setShowPasswordEdit] = useState(
    !serviceState.configuration?.userName
  )
  const [licenseResponse, setLicenseResponse] = useState({})
  const [isSaved, setIsSaved] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLicenseGenerated, setIsLicenseGenerated] = useState(false)
  const [isRefreshConfig, setIsRefreshConfig] = useState(false)
  const [showEditButton, setShowEditButton] = useState(serviceState.configuration?.username !== null)
  let intervalId = null

  const [staticPassword, setStaticPassword] = useState('')
  const initialValues = useMemo(
    () => ({
      sbcPrefix: serviceState.configuration?.sbcPrefix || '',
      userName: serviceState.configuration?.username || '',
      password: staticPassword|| '',
      tenantId: serviceState.configuration?.aadTenantId || '',
      m365License: serviceState.configuration?.m365License || '',
    }),
    [serviceState.configuration, staticPassword]
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: DirectRoutingConfigFormValidation,
    validateOnChange: true,
    onSubmit: (data) => handleConfigSubmit(data),
  })

  useEffect(() => {
    if (isRefreshConfig) {
      intervalId = setInterval(() => {
        executeWithTryCatch(getConfigurationDetails, handleGetConfigError)
      }, 3000)
    } else {
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [isRefreshConfig])

  const getConfigurationDetails = async () => {
    const response = await getCustomerConfiguration(gettingCompanyId())
    if (isResponseSuccess(response)) {
      if (response.data.result.isAppConsented) {
        dispatch(setConfiguration(response.data.result))
        setIsRefreshConfig(false)
        clearInterval(intervalId)
      }
    }
  }

  const handleGetConfigError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
  }

  const getCompanyId = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      return companyId
    }
  }

  const handleConfigSubmit = async (values) => {
    setBtnLoading((prev) => ({ ...prev, submitLoading: true }))
    const payload = {
      companyId: getCompanyId(),
      sbcPrefix: formik.values.sbcPrefix,
      username: formik.values.userName,
      tenantId: formik.values.tenantId,
      domainEast: licenseResponse?.domainEast,
      domainWest: licenseResponse?.domainWest,
      tempUserId: licenseResponse?.tempUserId,
    }
    executeWithTryCatch(async () => {
      const response = await api_AssignVoiceRoutePolicy(
        payload,
        payload.companyId
      )
      if (response.data.result?.automationDeploymentStatus > 0) {
        dispatch(setIsOrderActivatedView(false))
        if (!updateAdministrate) {
          goToServiceFunction('list')
        }
        onChange && onChange()
      }
      localStorage.removeItem('OrderActivated')
      localStorage.removeItem('isAlreadyActivated')
      localStorage.removeItem('oid')
      localStorage.removeItem('ct')

      onClose && onClose()
      toast.showToast(
        response.data?.message || TOAST_MESSAGES.DeploymentCompleted,
        'success'
      )
      setBtnLoading((prev) => ({ ...prev, submitLoading: false }))
    }, handleVoicePolicyAssignmentError)
  }

  const handleGrantPermission = () => {
    if (serviceState.configuration?.consentUrl) {
      setIsRefreshConfig(true)
      window.open(serviceState.configuration?.consentUrl, '_blank')
    } else {
      setIsRefreshConfig(false)
      setShowConsentAlert(true)
    }
  }

  const handleVoicePolicyAssignmentError = (error) => {
    setBtnLoading((prev) => ({ ...prev, submitLoading: false }))
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
  }

  const generateM365License = async () => {
    executeWithTryCatch(async () => {
      setBtnLoading((prev) => ({ ...prev, generateLicenseLoading: true }))
      const request = {
        companyId: gettingCompanyId(),
        tenantId: formik.values.tenantId,
      }
      const response = await api_generateM365License(request)
      setLicenseResponse(response.data.result)
      formik.setFieldValue('m365License', response.data?.result?.license)
      dispatch(
        setConfiguration({
          ...serviceState.configuration,
          m365License: response.data?.result?.license,
        })
      )
      setIsLicenseGenerated(response.data?.result?.license != null)
      setBtnLoading((prev) => ({ ...prev, generateLicenseLoading: false }))
      setIsSubmitted(true)
    }, handleGenerateLicenseError)
  }

  const handleGenerateLicenseError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setIsLicenseGenerated(false)
    setBtnLoading((prev) => ({ ...prev, generateLicenseLoading: false }))
  }

  const handleSave = async (values) => {
    executeWithTryCatch(async () => {
      setBtnLoading((prev) => ({ ...prev, saveLoading: true }))
      setShowEditButton(true)
      setStaticPassword('******')
      setStaticPassword('')
      const payload = {
        ...values,
        companyId: getCompanyId(),
      }
      setStaticPassword(payload.password)
      const response = await postDRConfiguration(payload)
      if (response) {
        dispatch(setConfiguration(response?.data.result))
        const configurationResponse = await getCustomerConfiguration(
          gettingCompanyId()
        )
        if (configurationResponse) {
          setIsSaved(true)
          dispatch(
            setConfiguration({
              ...configurationResponse.data.result,
              userName: configurationResponse.data.result.username,
              aadTenantId: configurationResponse.data.result.aadTenantId,
            })
          )
        }
      }
      setBtnLoading((prev) => ({ ...prev, saveLoading: false }))
    }, handleSaveError)
  }

  const handleSaveError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setBtnLoading((prev) => ({ ...prev, saveLoading: false }))
  }

  //conditions
  const isDisableSaveBtn =
    formik.values.tenantId == '' ||
    formik.values.sbcPrefix == '' ||
    formik.values.userName == '' ||
    formik.values.password == ''

  const handleInputChange = (e) => {
    formik.handleChange(e)
    setIsSaved(false)
    dispatch(
      setConfiguration({
        ...serviceState.configuration,
        [e.target.name]: e.target.value,
      })
    )
  }
  const handleEdit = () => {
    setShowEditButton(false)
    setStaticPassword('')
  }

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle
        id="scroll-dialog-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold" color="primary">
          Manage Account for{' '}
          {serviceName
            ? serviceName === PRODUCT_NAMES.DR
              ? PRODUCT_NAMES.TPS
              : serviceName
            : PRODUCT_NAMES.TPS}
        </Typography>
        <Tooltip title="Close">
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CancelIcon color="error" size="large" />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent dividers sx={{ width: '620px' }}>
          {props?.isLoading ? (
            <SkeletonTableLoader value={5}></SkeletonTableLoader>
          ) : (
            <>
              {checkPermission(
                [
                  PERMISSIONS.masterSettingUpdate,
                  PERMISSIONS.serviceproviderSettingUpdate,
                  PERMISSIONS.resellerSettingUpdate,
                  PERMISSIONS.customerSettingUpdate,
                ],
                commonState.currentCompanyDetails
              ) && (
                <FlexBox
                  item
                  xs={12}
                  sx={{ gap: 2, justifyContent: 'flex-end !important' }}
                >
                  {isSaved && !serviceState.configuration?.isAppConsented && (
                    <CaptionText
                      sx={{ color: (theme) => theme.palette.error.main }}
                    >
                      Please grant the necessary permissions to generate the
                      M365 license.
                    </CaptionText>
                  )}

                  <Tooltip title="Grant Permission">
                    <IconButton
                      disable={true}
                      onClick={handleGrantPermission}
                      sx={{ padding: 0 }}
                    >
                      {/* Once IsAppConsented is working fine after that remove this comment and the remove code isSaved */}
                      <GppGoodIcon
                        color={
                          serviceState.configuration?.isAppConsented
                            ? 'success'
                            : 'default'
                        }
                      />
                      {/* <GppGoodIcon color={isSaved ? 'success' : 'default'} /> */}
                    </IconButton>
                  </Tooltip>
                </FlexBox>
              )}
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  <Typography>{requiredLabel('Domain', true)}</Typography>
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <CustomTextField
                    fullWidth
                    id="sbcPrefix"
                    name="sbcPrefix"
                    size="small"
                    disabled
                    value={formik.values.sbcPrefix}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.sbcPrefix &&
                      Boolean(formik.errors.sbcPrefix)
                    }
                    helperText={
                      formik.touched.sbcPrefix && formik.errors.sbcPrefix
                    }
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  <Typography>
                    {requiredLabel('Microsoft Admin Account', true)}
                  </Typography>
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <CustomTextField
                    fullWidth
                    id="userName"
                    name="userName"
                    size="small"
                    autoComplete="off"
                    value={formik.values.userName}
                    onChange={handleInputChange}
                    error={
                      formik.touched.userName && Boolean(formik.errors.userName)
                    }
                    helperText={
                      formik.touched.userName && formik.errors.userName
                    }
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  <Typography>{requiredLabel('Password', true)}</Typography>
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <CustomTextField
                    fullWidth
                    id="password"
                    name="password"
                    size="small"
                    autoComplete="off"
                    disabled={showEditButton}
                    placeholder={!showEditButton ?'': '**********'}
                    type={'text'}
                    value={showEditButton? '':formik.values.password}
                    onChange={handleInputChange} 
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    onBlur={formik.handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                        {/* <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton> */}
                        <IconButton
                            onClick={() => handleEdit()}
                            aria-label="edit"
                            style={{
                              float: 'end',
                              borderRadius: 0,
                              fontSize: '20px',

                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: '15px',
                                verticalAlign: 'end',
                                margin: '0 0px 0 0px',
                              }}
                            />{' '}
                          </IconButton>
                      </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  <Typography>{requiredLabel('Tenant ID', true)}</Typography>
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <CustomTextField
                    fullWidth
                    id="tenantId"
                    name="tenantId"
                    size="small"
                    sx={{ flexGrow: 1 }}
                    value={formik.values.tenantId}
                    onChange={handleInputChange}
                    error={
                      formik.touched.tenantId && Boolean(formik.errors.tenantId)
                    }
                    helperText={
                      formik.touched.tenantId && formik.errors.tenantId
                    }
                    onBlur={formik.handleBlur}
                  />
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item xs={12} sm={6} md={3} lg={5}>
                  {' '}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={9}
                  lg={7}
                  sx={{ display: 'flex', gap: 2 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      borderRadius: '4px',
                      minWidth: 'fit-content',
                    }}
                    disabled={isDisableSaveBtn || isSaved}
                    onClick={() => handleSave(formik.values)}
                  >
                    {isSaved ? 'Saved' : 'Save'}
                    {btnLoading.saveLoading ? (
                      <>
                        {' '}
                        &nbsp;{' '}
                        <CircularProgress
                          size={20}
                          sx={{ color: '#fff' }}
                        />{' '}
                      </>
                    ) : null}{' '}
                  </Button>
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  <Typography>
                    {requiredLabel('Microsoft 365 License', true)}
                  </Typography>
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <CustomTextField
                    fullWidth
                    id="m365License"
                    name="m365License"
                    size="small"
                    value={formik.values.m365License}
                    disabled
                  />
                </Grid>
              </FlexContainer>
              <FlexContainer>
                <Grid item sx={12} sm={6} md={3} lg={5}>
                  {' '}
                </Grid>
                <Grid item sx={12} sm={6} md={9} lg={7}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Button
                      text="Generate M365 License"
                      color="primary"
                      variant="contained"
                      disabled={
                        !serviceState.configuration?.isAppConsented ||
                        btnLoading.generateLicenseLoading ||
                        isLicenseGenerated
                      }
                      onClick={generateM365License}
                      sx={{ minWidth: 'fit-content' }}
                    >
                      Generate M365 License
                    </Button>
                    {btnLoading.generateLicenseLoading && (
                      <CircularProgress
                        size={25}
                        style={{
                          color: '#0984E3',
                        }}
                      />
                    )}
                  </div>
                  {btnLoading.generateLicenseLoading ? (
                    <CaptionText
                      sx={{
                        color: (theme) => theme.palette.error.main,
                        marginTop: '10px',
                      }}
                    >
                      This operation may take some time. The submit button will
                      be enabled once the process is complete.
                    </CaptionText>
                  ) : null}
                </Grid>
              </FlexContainer>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <FlexContainer justifyContent="end !important" gap={1}>
            <ButtonComponent
              text="Cancel"
              color="primary"
              variant="outlined"
              onClick={onClose}
            />
            <Button
              color="primary"
              variant="contained"
              disabled={!isSubmitted}
              onClick={() => handleConfigSubmit(formik.values)}
            >
              Submit{' '}
              {btnLoading.submitLoading ? (
                <>
                  {' '}
                  &nbsp; <CircularProgress
                    size={20}
                    sx={{ color: ' #fff' }}
                  />{' '}
                </>
              ) : null}{' '}
            </Button>
          </FlexContainer>
        </DialogActions>
      </form>
      <ConfirmationModal
        title="Info"
        content={`Please set your Azure AD Tenant Id to grant admin consent!`}
        isOpen={showConsentAlert}
        hiddenConform={true}
        handleClose={() => setShowConsentAlert(false)}
      />
    </Dialog>
  )
}

export default DirectRoutingConfigDialog
